import { BaseElement, html, css } from 'Elements';

class InputBusinessHours extends BaseElement {

  static get styles() {
    return [
      css`
        :host {
          font-size:0.9em;
          display:flex;
          align-items:center;
          gap:20px;
        }

        div.label {
          white-space:nowrap;
        }

        .inputs sl-input {
          width:55px;
        }

        .inputs sl-input::part(input) {
          font-size:0.9em;
          padding-left: 5px;
          padding-right: 1px;
        }

        .inputs sl-input::part(input)::-webkit-inner-spin-button,
        .inputs sl-input::part(input)::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
          transform: scale(1.5) !important;
          margin: 0 !important;
        }

        .inputs {
          display:flex;
          align-items:center;
          gap:5px;
          padding:1px;
          margin:1px;
        }

        .inputs > div {
          white-space:nowrap;
        }

        .inputs.error {
          outline:1px solid var(--sl-color-danger-400);
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: Object },
      error: { type: Boolean },
      label: { type: String },
    };
  }

  static get translations() {
    return [
      {
        english:{
          translation: {
            from:'',
            to:'h /',
            hour:'h',
          }
        },
        french:{
          translation: {
            from:'',
            to:'h /',
            hour:'h',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.error = false;
    this.value = {
      start: 0,
      end: 0,
    }
  }

  onChange(ev) {

    this.error = false;

    this.inputStart = this.qs('sl-input[name="start"]');
    this.inputEnd = this.qs('sl-input[name="end"]');

    const validStart = this.inputStart.checkValidity();
    const validEnd = this.inputEnd.checkValidity();
    if (!validStart) {
      this.inputStart.reportValidity();
      this.error = true;
      return;
    }

    if (!validEnd) {
      this.inputEnd.reportValidity();
      this.error = true;
      return;
    }

    this.value = {
      start: parseInt(this.inputStart.value) || 0,
      end: parseInt(this.inputEnd.value) || 0,
    }

    if (this.value.start > this.value.end) {
      this.value.end = this.value.start +1;
      this.inputEnd.value = this.value.end +1;
    }

    this.error = false;

    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  render() {
    this.value = this.value || { start: 0, end: 0 };

    return html`
      <div part="form-control" class=" form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="inputs ${this.error ? 'error': ''}">
          <div>${this._tl('from')}</div>
          <sl-input size="small" name="start" type="number" value=${this.value.start} min="0" max="23" @sl-change=${this.onChange} @sl-input=${this.onChange}></sl-input>
          <div>${this._tl('to')}</div>
          <sl-input size="small" name="end" type="number" value=${this.value.end} min="0" max="23" @sl-change=${this.onChange} @sl-input=${this.onChange}></sl-input>
          <div>${this._tl('hour')}</div>
        </div>
      </div>
    `;
  }
}

customElements.define('input-business-hours', InputBusinessHours);