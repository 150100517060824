import { BaseElement, html, css } from 'Elements';
import { Lang } from 'Utils';

class ContractSLA extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          display:block;
          font-size:0.9em;
          width:100%;

        }

        label {
          display:inline-block;
          margin:5px;
        }

        sl-select::part(display-input) {
          font-size:0.95em;
        }

        sl-option::part(base),
        sl-option::part(label) {
          font-size:0.9rem;
          line-height:initial;
        }

        sl-checkbox::part(label),
        sl-checkbox::part(base) {
          padding:0px;
          margin:0px;
        }

        .ucfirst {
          text-transform:capitalize;
        }

        table {
          width:100%;
          margin-top:10px;
          border-collapse:collapse;
        }

        thead {
          background-color:var(--nsys-blue5);
          color:white;
          text-align:left;
        }

        td,th {
          padding:5px;
          border: 1px solid var(--sl-panel-border-color);
          font-weight:normal;
          white-space:nowrap;
        }

        .disabled {
          pointer-events:none;
        }

        .queries {
          width: 100%;
          container-type: inline-size;
          container-name: queries;
        }

        .tables {
          display: flex;
          flex-direction: row;
          gap:10px;
        }

        .tables > * {
          flex:1;
        }

        @container queries (inline-size < 1200px) {
          .tables {  
            flex-direction: column;
          }
        }

        th m-icon {
          margin-top:5px;
          margin-left:10px;
          cursor:pointer;
        }

        .th_flex {
          width:100%;
          display:flex;
          align-items:center;
          justify-content:space-between;
        }

        .prio_p1 {
          background-color:var(--pp1);
          color:white;
        }

        .prio_p2 {
          background-color:var(--pp2);
          color:white;
        }

        .prio_p3 {
          background-color:var(--pp3);
          color:white;
        }

        .prio_p4 {
          background-color:var(--pp4);
          color:white;
        }

      `
    ]
  }

  static get translations() {
    return [
      {
        english:{
          translation: {
            days:'days',
            hours:'hours',
            sla:{
              title:'Service Level Agreement (SLA)',
              custom: 'Custom contract',
              none:'No contract',
              continuous: '24/7 contract'
            },
            delay:{
              '30mn':'30 minutes',
              '45mn':'45 minutes',
              '60mn':'1 hour',
              '120mn':'2 hours',
              '180mn':'3 hours',
              '240mn':'4 hours'              
            },
            title:{
              'prio':'Priorities',
              'take':'Take over',
              'takeho':'Take over BO',
              'takehno':'Take over NBO',
              'first':'First response',
              'firstho':'First response BO',
              'firsthno':'First response NBO',
            }
          }
        },
        french:{
          translation: {
            days:'jours',
            hours:'horaires',
            sla:{
              title:'Accord de Niveau de Service (SLA)',
              custom: 'Contrat personnalisé',
              none:'Aucun contrat',
              continuous: 'Contrat 24/7'
            },
            delay:{
              '30mn':'30 minutes',
              '45mn':'45 minutes',
              '60mn':'1 heure',
              '120mn':'2 heures',
              '180mn':'3 heures',
              '240mn':'4 heures'
            },
            title:{
              'prio':'Priorités',
              'take':'Prise en charge',
              'takeho':'Prise en charge HO',
              'takehno':'Prise en charge HNO',
              'first':'Première réponse',
              'firstho':'Première réponse HO',
              'firsthno':'Première réponse HNO',
            }
          }
        }
      }
    ]
  }

  static get properties() {
    return {
      value: { type: Object,  },
      name: { type: String },
      readOnly: { type: Boolean },
      timestamp: { type: Number }
    }
  }

  constructor() {
    super();
    
    this.value = { };
    this.debug = false;
    
    const hoCustom = '540_1080';

    this.defaultCustom = {
      type:'custom',
      days:{
        'd1': { hours:hoCustom, enabled:true },
        'd2': { hours:hoCustom, enabled:true },
        'd3': { hours:hoCustom, enabled:true },
        'd4': { hours:hoCustom, enabled:true },
        'd5': { hours:hoCustom, enabled:true },
        'd6': { hours:null,     enabled:false },
        'd7': { hours:null,     enabled:false },
      },
      priorities:{
        'p1': { takeOverDelayHO:30,   takeOverDelayHNO:45,  firstResponseDelayHO:30,  firstResponseDelayHNO:45 },
        'p2': { takeOverDelayHO:45,   takeOverDelayHNO:60,  firstResponseDelayHO:45,  firstResponseDelayHNO:60 },
        'p3': { takeOverDelayHO:60,   takeOverDelayHNO:120, firstResponseDelayHO:60,  firstResponseDelayHNO:120 },
        'p4': { takeOverDelayHO:120,  takeOverDelayHNO:240, firstResponseDelayHO:120, firstResponseDelayHNO:240 },
      }
    }

    const hoContinuous = '0_1440';
    this.defaultContinuous = {
      type:'continuous',
      days:{
        'd1': { hours:hoContinuous, enabled:true },
        'd2': { hours:hoContinuous, enabled:true },
        'd3': { hours:hoContinuous, enabled:true },
        'd4': { hours:hoContinuous, enabled:true },
        'd5': { hours:hoContinuous, enabled:true },
        'd6': { hours:hoContinuous, enabled:true },
        'd7': { hours:hoContinuous, enabled:true },
      },
      priorities:{
        'p1': { takeOverDelay:30,   firstResponseDelay:30 },
        'p2': { takeOverDelay:45,   firstResponseDelay:45 },
        'p3': { takeOverDelay:60,   firstResponseDelay:60 },
        'p4': { takeOverDelay:120,  firstResponseDelay:120 }
      }
    }
  }

  /*
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('value')) {
      if (!this.value.type) this.value.type = 'none';
      this.requestUpdate();
      this._log.debug('updated', this.value);
    }
  }
  */

  sendEvent() {
    this._log.debug('sendEvent', this.value);
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  async onChangeSlaType(ev) {
    const type = ev.target.value;

    // empty this.value object with a loop on keys
    if (!this.value) this.value = {};

    Object.keys(this.value).forEach(key => {
      delete this.value[key];
    });

    this.value.type = type;

    if (type === 'custom') {
      this.value.days = { ...this.defaultCustom.days };
      this.value.priorities = { ...this.defaultCustom.priorities };
    } else if (type === 'continuous') {
      this.value.days = { ...this.defaultContinuous.days };
      this.value.priorities = { ...this.defaultContinuous.priorities };
    } else {
      delete this.value.days;
      delete this.value.priorities;
    }

    await this.requestUpdate();
    this.sendEvent();
  }

  renderSelectSlaType() {
    if (this.readOnly) {
      return html`
        <div class="readonly">
          <!-- <sl-input size="small" value="${this._tl('sla.'+this.value.type)}" readonly></sl-input> -->
          ${this._tl('sla.'+this.value.type)}
        </div>
      `;
    }
    return html`
      <div>
        <sl-select hoist value="${this.value?.type  || 'none'}" size="small" @sl-change=${this.onChangeSlaType}>
          <sl-option value="none">${this._tl('sla.none')}</sl-option>
          <sl-option value="custom">${this._tl('sla.custom')}</sl-option>
          <sl-option value="continuous">${this._tl('sla.continuous')}</sl-option>
        </sl-select>
      </div>
    `;
  }

  onChangeHours(ev) {
    this.value.days[ev.target.getAttribute('name')].hours = ev.detail;
    this.sendEvent();
  }

  onToggleServiceDay(ev) {
    this.value.days[ev.target.getAttribute('name')].enabled = ev.target.checked;
    this.requestUpdate();
    this.sendEvent();
  }

  renderTableHours() {

    if (!this.value || !this.value?.type || this.value?.type === 'none') return '';
    this._log.debug('renderTableHours', this.value.type);

    return html`
      <div>
        <table>
          <thead>
            <tr>
              <th width="20"></th>
              <th class="ucfirst" width="130">${this._tl('days')}</th>
              <th class="ucfirst">${this._tl('hours')}</th>
            </tr>
          </thead>
          <tbody>
            ${Array.from({length: 7}, (_, i) => i + 1).map(i => html`
              <tr>
                <td><sl-checkbox ?checked=${this.value.days[`d${i}`].enabled} name="d${i}" class="${this.readOnly ? 'disabled' : ''}" @sl-change=${this.onToggleServiceDay}></sl-checkbox></td>
                <td class="ucfirst">${Lang.getDayOfWeek(i)}</td>
                <td>
                  ${this.value.days[`d${i}`].enabled 
                    ? html`<range-numbers showCursor step=1 value="${this.value.days[`d${i}`].hours}" name="d${i}" units="hourminute" ?disabled=${this.readOnly} @change=${this.onChangeHours}></range-numbers>`
                    : ''
                  }
                </td>
              </tr>
            `)}
          </tbody>
        </table>
      </div>
    `
  }

  onChangeSlaDelay(ev) {
    const target = ev.target;
    const prio = target.getAttribute('prio');
    const key = target.getAttribute('key');
    this.value.priorities[prio][key] = parseInt(target.value);
    this._log.debug('onChangeSlaDelay', prio, key, target.value);
    this.sendEvent();
  }

  renderSelectDelay(prio, key) {

    const value = this.value.priorities[prio][key];

    if (this.readOnly) {
      return html`
        <!-- <sl-input size="small" value="${this._tl(`delay.${value}mn`)}" readonly></sl-input> -->
        <div class="readonly">${this._tl(`delay.${value}mn`)}<div>
      `;
    }

    return html`
      <sl-select value="${value}" size="small" prio="${prio}" key="${key}" @sl-change=${this.onChangeSlaDelay}>
        <sl-option value="30">${this._tl('delay.30mn')}</sl-option>
        <sl-option value="45">${this._tl('delay.45mn')}</sl-option>
        <sl-option value="60">${this._tl('delay.60mn')}</sl-option>
        <sl-option value="120">${this._tl('delay.120mn')}</sl-option>
        <sl-option value="180">${this._tl('delay.180mn')}</sl-option>
        <sl-option value="240">${this._tl('delay.240mn')}</sl-option>
      </sl-select>
    `;
  }

  openHelp(key) {
    console.log('openHelp', key);
  }
  
  renderTableDelay() {

    if (!this.value || !this.value?.type || this.value?.type === 'none') return '';
    this._log.debug('renderTableDelay', this.value.type);

    if (this.value.type === 'continuous') {
      return html`
        <div class="delay">
          <table>
            <thead>
              <tr>
                <th width="90"><div class="th_flex"><div>${this._tl('title.prio')}</div><m-icon name="help" @click=${ev => this.openHelp('prio')}></m-icon></div></th>
                <th><div class="th_flex"><div>${this._tl('title.take')}</div><m-icon name="help" @click=${ev => this.openHelp('take')}></m-icon></div></th>
                <th><div class="th_flex"><div>${this._tl('title.first')}</div><m-icon name="help" @click=${ev => this.openHelp('first')}></m-icon></div></th>
              </tr>
            </thead>
            <tbody>
              ${Object.keys(this.value.priorities).map(p => html`
                <tr>
                  <td align="center" class="ucfirst prio_${p}">${p}</td>
                  <td align="center">${this.renderSelectDelay(p, 'takeOverDelay')}</td>
                  <td align="center">${this.renderSelectDelay(p, 'firstResponseDelay')}</td>
                </tr>
              `)}
            </tbody>
          </table>
        </div>
      `
    }

    return html`
      <div class="delay">
        <table>
          <thead>
            <tr>
              <th width="90"><div class="th_flex"><div>${this._tl('title.prio')}</div><m-icon name="help" @click=${ev => this.openHelp('prio')}></m-icon></div></th>
              <th><div class="th_flex"><div>${this._tl('title.takeho')}</div><m-icon name="help" @click=${ev => this.openHelp('takeho')}></m-icon></div></th>
              <th><div class="th_flex"><div>${this._tl('title.takehno')}</div><m-icon name="help" @click=${ev => this.openHelp('takehno')}></m-icon></div></th>
              <th><div class="th_flex"><div>${this._tl('title.firstho')}</div><m-icon name="help" @click=${ev => this.openHelp('firstho')}></m-icon></div></th>
              <th><div class="th_flex"><div>${this._tl('title.firsthno')}</div><m-icon name="help" @click=${ev => this.openHelp('firsthno')}></m-icon></div></th>
            </tr>
          </thead>
          <tbody>
            ${Object.keys(this.value.priorities).map(p => html`
              <tr>
                <td align="center" class="ucfirst prio_${p}">${p}</td>
                <td>${this.renderSelectDelay(p, 'takeOverDelayHO')}</td>
                <td>${this.renderSelectDelay(p, 'takeOverDelayHNO')}</td>
                <td>${this.renderSelectDelay(p, 'firstResponseDelayHO')}</td>
                <td>${this.renderSelectDelay(p, 'firstResponseDelayHNO')}</td>
              </tr>
            `)}
          </tbody>
        </table>
        <br/><br/>
      </div>
    `
  }


  render() {
    this._log.debug('render', JSON.stringify(this.value));
    
    return html`
      <div class="select">${this.renderSelectSlaType()}</div>
      <div class="queries">
        <div class="tables">
          ${this.renderTableHours()}
          ${this.renderTableDelay()}
        </div>
      </div>
    `;
  }
}

customElements.define('contract-sla', ContractSLA);