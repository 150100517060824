import { BaseElement, html, css } from 'Elements';
import { Fetcher } from 'Utils';

class Page extends BaseElement {
  static get styles() {
    return [
      css`
        sl-progress-bar {
          width:200px;
          margin-left:auto;
          margin-right:auto;
        }

        .container {
          display:flex;
          width:100%;
        }


        sl-tree {
          background-color: var(--sl-color-neutral-0);
        }

        sl-tree-item {
          --indent-size:15px;
          --indent-guide-width:1px;
        }

        sl-tree-item::part(label) {
          font-family:Calibri;
          font-size:1rem;
          color:var(--text-color);
          width:100%;
          position:relative;
          line-height:1.5em;
          display: flex;
          justify-content: stretch;
        }

        sl-tree-item span {
          display:block;
          white-space:nowrap;
        }

        sl-tree-item::part(item) {
          font-family:Calibri;
          color:var(--text-color);
          width:100%;
          padding-left:0px;
          position:relative;
        }

        sl-tree-item::part(expand-button) {
          padding:3px;
          padding-right:7px;
        }

        sl-tree-item.disabled::part(label) {
          color:var(--sl-color-warning-600);
        }

        sl-tree-item.person::part(expand-button) {
          width:0px;
        }

        .menu_item {
          display: flex;
          width:100%;
          white-space: nowrap;
          align-items: center;
          justify-content: space-between;
        }

        m-icon {
          cursor:pointer;
        }

        m-icon[name="search"] {
          color:lightgray;
        }

        sl-button::part(spinner) {
          font-size:18px;
          margin-top:-5px;
          margin-left:5px;
        }

        m-icon[name="add"] {
          font-size:30px;
          margin-top:-4px;
        }

        m-icon[name="refresh"] {
          font-size:30px;
          margin-top:-4px;
        }

        m-icon[name="menu"] {
          font-size:30px;
          margin-right:5px;
        }

        .start {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding:5px;
        }

        .end {
        }

        sl-dropdown {
          padding-top: 6px;
          right:0px;
          position: absolute;
        }

        sl-menu-item::part(label) {
          font-size:0.8em;
        }
        
      `
    ];
  }

  static get properties() {
    return {
      groups: { type: Array },
      selected: { type: String },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            refresh:'Refresh',
            add:'Add a group'
          },
        },
        french:{
          translation: {
            refresh:'Rafraîchir',
            add:'Ajouter un groupe'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.q = undefined;
    this.loading = true;
    this.visible = 0;
    this.reallyVisible = false;
    this.groups = [];
    this.selections = [];
    this.onNodeUpdated = this.onNodeUpdated.bind(this);
    this.handleUserDragStart = this.handleUserDragStart.bind(this);
    this.handleUserDragMove = this.handleUserDragMove.bind(this);
    this.handleUserDragEnd = this.handleUserDragEnd.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('registry-updated', this.onNodeUpdated);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('registry-updated', this.onNodeUpdated);
  }

  async load() {
    await this.refreshData();
  }

  async onNodeUpdated(ev) {
    if (!ev.detail?.kc_id) {
      //console.error('no kc_id');
      return;
    }
    if (!this.reallyVisible) {
      return;
    }

    await this.refreshData();
    const kcId = ev.detail.kc_id;
    setTimeout(() => {
      this.setSelected(kcId, true);
    }, 100);
  }

  async refreshData(ev) {
    let target;
    if (ev) {
      target = ev.target.tagName === 'M-ICON' ? target = ev.target.parentElement : target = ev.target;
      target.loading = true;
    }

    this.loading = true;
    if (target) target.loading = true;

    let url = 'private/admin/keycloak/groups';
    if (this.q) url+='?q='+this.q;
    const response = await Fetcher.get(url);
    if (response && response.data) {
      this.groups = response.data.groups;
    }
    this.loading = false;
    if (target) target.loading = false;
    this.requestUpdate();
  }

  async setSelected(id, scroll) {
    const items = this.shadowRoot.querySelectorAll('sl-tree-item');
    const el = Array.from(items).find(item => item.getAttribute('data-id') === id);
    if (el) {
      el.selected = true;
      // scroll the window to show the element
      if (scroll) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.selected = id;
      this.group = this.getSelected();
    } else {
      console.error(`Element not found ${id}`);
    }
  }


  findGroupById(id, groups) {
    for (const group of groups) {
      if (group.id === id) {
        return group;
      }
      if (group.subGroups) {
        const found = this.findGroupById(id, group.subGroups);
        if (found) return found;
      }
    }
    return undefined; // Retourne undefined si le groupe n'est pas trouvé
  }

  getSelected() {
    return this.findGroupById(this.selected, this.groups);
  }

  async handleTreeItemClick(event) {
    event.preventDefault();
    event.stopPropagation();
    const node = event.target;
    if (!this.selections) this.selections = [];
    node.selected = !node.selected;
    if (node.selected) {
      this.selections.push(node);
    } else {
      this.selections = this.selections.filter(item => item !== node);
    }
  }

  handleSearchInput() {
    this.q = this.shadowRoot.querySelector('sl-input').value;
    this.refreshData();
  }
  
  handleUserDragStart(event, item) {
    this._log.debug('handleUserDragStart', item);
    const node = event.target;
    const exists = this.selections.find(sel => sel === node);
    if (!exists) {
      node.selected = true;
      this.selections.push(node);
    }


    this.dragImage = document.createElement('div');
    this.dragImage.id = 'dragImage';
    this.dragImage.className='dragged forbidden';
    if (this.selections.length > 1) {
      this.dragImage.innerHTML = `${this.selections.length} utilisateurs`;
    } else {
      this.dragImage.innerHTML = `${item.email}`;
    }
    document.body.appendChild(this.dragImage);

    const img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; // Image transparente de 1x1 pixel
    event.dataTransfer.setDragImage(img, 0, 0);

    const users = [];
    for (const sel of this.selections) {
      users.push(sel.user);
    }

    const ev = new CustomEvent('keycloak-browser-drag-start', { detail: users });
    window.dispatchEvent(ev);

    window.addEventListener('drag', this.handleUserDragMove);
    window.addEventListener('dragend', this.handleUserDragEnd);
    //event.dataTransfer.setDragImage(dragImage, 0, 0);
    //setTimeout(() => { document.body.removeChild(dragImage); }, 0);
  }

  handleUserDragMove(event) {
    this.dragImage.style.left = `${event.clientX}px`; // +10 pour un petit décalage
    this.dragImage.style.top = `${event.clientY + 10}px`;
  }
  
  handleUserDragEnd() {
    //console.log('kcbrowser handleUserDragEnd');
    window.removeEventListener('dragend', this.handleUserDragEnd);
    window.removeEventListener('drag', this.handleUserDragMove);
    this.dragImage.remove();
  }

  isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node instanceof ShadowRoot ? node.host : node.parentNode;
    }
    return false;
  }

  showDropForbidden(ev) {
    if (this.floatingDiv) this.floatingDiv.style.border = '2px solid #FF000044';
    ev.preventDefault();
    ev.stopPropagation();
    this.dropAllowed = false;
  }

  showDropAllowed(ev) {
    this.floatingDiv.style.border = '2px solid #00FF0044';
    ev.preventDefault();
    ev.stopPropagation();
    this.dropAllowed = true;
  }

  handleUserDragOver() {
  }

  handleUserDragOverx(event) {
    /*
    if (!this.floatingDiv) return;
    this.floatingDiv.style.left = `${event.clientX - this.floatingDiv.offsetWidth/2}px`; // +10 pour un petit décalage
    this.floatingDiv.style.top = `${event.clientY + 10}px`;
    */
    let target = event.target;

    if (target.tagName !== 'SPAN') {
      this.showDropForbidden(event);
      return;
    }

    while (target && target.tagName !== 'SL-TREE-ITEM') {
      target = target.parentElement;
    }

    // avoid drag on myself
    if (this.draggedElement.item._id !== target.item._id) {
      // avoid drag on parent
      if (this.draggedElement.item.parentId !== target.item._id) {
        // avoid drag on children
        if (!this.isDescendant(this.draggedElement, target)) {
          // avoid drag on nodeType not accepting children
          if (this.nodeTypes[target.item.nodeType]?.noChilds) {
            this.showDropForbidden(event);
            return;
          }

          // allow drop
          this.showDropAllowed(event);
          return;
        }
      }
    }

    this.showDropForbidden(event);
  }

  renderTreeUsers(users) {

    //@dragover="${(event) => this.handleUserDragOver(event, user)}"

    return users.map(user => html`
      <sl-tree-item 
        .user=${user}
        draggable="true"
        class="person"
        @dragstart="${(event) => this.handleUserDragStart(event, user)}"
        @click="${this.handleTreeItemClick}"
      >
        <m-icon name="person"></m-icon>
        ${user.email}
      </sl-tree-item>
    `);
  }

  renderTreeGroups(group) {
    return html`
      <sl-tree-item 
        data-id="${group.id}" 
        title="${group.id}" 
        class="${!group.attributes?.io_created_from ? 'disabled' : ''}"
        expanded
      >
        <div class="menu_item">
          <span title="${group.name}">${group.attributes?.displayName || group.name}</span>
          <!--
          <sl-dropdown>
            <m-icon slot="trigger" name="more_vert"></m-icon>
            <sl-menu>
              <sl-menu-item @click="${this.updateItem}" ?disabled=${!group.attributes?.createdFrom}>Renommer</sl-menu-item>
              <sl-divider></sl-divider>
              <sl-menu-item @click="${this.deleteItemConfirm}" ?disabled=${!group.attributes?.createdFrom}>Supprimer</sl-menu-item>
            </sl-menu>
          </sl-dropdown>
          -->
        </div>
        ${group.subGroups ? group.subGroups.map(subGroup => this.renderTreeGroups(subGroup)) : ''}
        ${this.renderTreeUsers(group.users)}
      </sl-tree-item>
    `;
  }

  render() {
    return html`
      ${this.loading 
        ? html`
            <sl-progress-bar indeterminate></sl-progress-bar>
          ` 
        : html`
            <sl-input size="small" name="kq" @sl-change="${this.handleSearchInput}" clearable>
              <m-icon name="search" slot="suffix"></m-icon>
            </sl-input><br/>
            
            ${this.groups.length 
              ? html`
                <sl-tree selection="multiple leaf">
                  ${this.groups.map(group => this.renderTreeGroups(group))}
                </sl-tree>
                <br/><br/><br/>
                `
              : html`<div>Aucun groupe disponible</div>`
            }
          `
      }
    `;
  }

}

customElements.define('keycloak-tree', Page);